import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'tenant',
    name: 'tenant.index',
    component: () => import('./views/pages/Index.vue'),
    meta: {
      title: 'Cityview overzicht',
    },
  },
];
