<script setup lang="ts">
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Image from '@libero/ui-framework/Image/Image.vue';
import Scrollable from '@libero/ui-framework/Scrollable/Scrollable.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView, useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();

const logo = computed(
  () => `/assets/environments/${import.meta.env.VITE_ENVIRONMENT}/apple-touch-icon.png`,
);
</script>

<template>
  <div class="auth-layout-wrapper">
    <div class="auth-layout">
      <Scrollable isAbsolute>
        <Stack>
          <Stack class="auth-layout-header">
            <Cluster class="auth-layout-title" alignItems="center">
              <Image :url="logo" class="auth-layout-logo" />
              <Typography type="heading" semiBold isDark>{{ t('cityview-overview') }}</Typography>
            </Cluster>

            <Typography v-if="!route.meta.hideTitle" tag="h1" type="heading" size="xxl">
              {{ route.meta.title }}
            </Typography>
          </Stack>

          <RouterView />
        </Stack>
      </Scrollable>
    </div>

    <div class="auth-layout-image">
      <Image url="/assets/images/login.png" alt="login image" shouldFill />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/cityview-overview/modules/app/views/layouts/AuthLayout/AuthLayout.scss';
</style>
