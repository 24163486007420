<script setup lang="ts">
import PageTitle from '@libero/ui-framework/PageTitle/PageTitle.vue';
import { RouterView, useRoute } from 'vue-router';

const route = useRoute();
</script>

<template>
  <div class="app-layout">
    <div class="app-layout-header">
      <PageTitle :title="route.meta.title" />
    </div>

    <div class="app-layout-content">
      <RouterView />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/cityview-overview/modules/app/views/layouts/AppLayout/AppLayout.scss';
</style>
